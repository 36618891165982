import {
  Component,
  OnInit,
  AfterContentInit,
  OnChanges,
  ViewChild,
  SimpleChanges,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from "../../../../../environments/environment";
import { ApiServicesService } from "../../services/api-services.service";
import { ToastrService } from "ngx-toastr";
import { UploadDocumentService } from "src/app/services/upload-document.service";
import { AuthenticationService } from "../../../../services/authentication.service";
import { ConfirmationModalComponent } from "src/app/modules/shared/modal/confirmation-modal/confirmation-modal.component";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { FileUploadLibraryService } from "file-upload-library";
import moment from "moment";

@Component({
  selector: "app-document-list",
  templateUrl: "./document-list.component.html",
  styleUrls: ["./document-list.component.scss"],
})
export class DocumentListComponent implements OnInit {
  documentDetails: any;
  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<any>;
  envPath: any;
  @Input() isViewMode : boolean;
  selectFamilyDetails: any = [];
  selectedMenu: any = [
    "My Personal Documents",
    "My Educational Documents",
    "My Work Experience Documents",
    "My Immigration Documents",
    "Additional Documents"
  ];
  loadData: any;
  selectedValues = [];
  bulkDownloadCheckboxes: any[] = [];
  selectedFiles: any[] = [];
  onlyFormBuilder;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  

  columns = [
    {
      columnDef: "fileType",
      header: "Type",
      columnType: "text",
      customClass: "col-md-4",
    },
    {
      columnDef: "fileName",
      header: "Name",
      columnType: "text",
      customClass: "col-md-6",
    },
    // { columnDef: 'fileLocation', header: 'Action', columnType: 'icon', customClass: 'col-md-2' }
  ];

  beneficiaryId;
  boolvar: boolean;
  selectedId: any;

  @Output() removeElement: EventEmitter<number> = new EventEmitter();
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private apiServices: ApiServicesService,
    private toastService: ToastrService,
    private uploadDocumentService: UploadDocumentService,
    public dialog: MatDialog,
    public uploadService: FileUploadLibraryService
  ) {
    this.onlyFormBuilder = this.route.snapshot.queryParams.pageFrom;
    if(this.onlyFormBuilder === 'FB') {
      this.beneficiaryId = (sessionStorage.getItem('FbEmployeeId')) ? parseInt(sessionStorage.getItem('FbEmployeeId')) : 0;
      this.selectedMenu.splice(this.selectedMenu.findIndex(e => e === "My Immigration Documents"), 1);
    } else {
      this.beneficiaryId = (sessionStorage.getItem('listBeneficiaryId')) ? parseInt(sessionStorage.getItem('listBeneficiaryId')) : (this.authenticationService.currentUserValue ? this.authenticationService.currentUserValue['beneficiaryId'] : sessionStorage.getItem('beneficiaryId'));
    }
  }

  ngOnInit() {
        this.envPath = environment.docs;
        this.getBeneficiarySelfDetails();
        this.getBeneficiaryFamilyDetails();
        this.boolvar = true;
        this.uploadDocumentService.uploaded$.subscribe((status) => {
          //this.loadDocumentList();
          if (status) {
            this.fetchFamilyDocument();
          }
        });
        //IM-3066 - remove deleted file.
        this.apiServices.deletedfile$.subscribe((status) => {
          // this.loadDocumentList();
          if (status) {
            this.fetchFamilyDocument();
          }
        });
        this.loadDocumentList();
  }

  getBeneficiarySelfDetails() {
    return this.apiServices
      .getBeneficiaryDetails(this.beneficiaryId, "self")
      .subscribe((beneficiarySelfInfo: any) => {
        this.selectFamilyDetails.push({
          relation: "SELF",
          familyid: this.beneficiaryId,
          name: beneficiarySelfInfo.fName,
          boolvar: true,
        });
        this.selectedId = this.beneficiaryId;
      });
  }

  getBeneficiaryFamilyDetails() {
    return this.apiServices
      .getBeneficiaryDetails(this.beneficiaryId, "family")
      .subscribe((beneficiaryFamilyInfo) => {
        if (beneficiaryFamilyInfo.length > 0) {
          beneficiaryFamilyInfo.map((str, index) => {
            this.selectFamilyDetails.push({
              relation: beneficiaryFamilyInfo[index].relationShipType.code,
              familyid: beneficiaryFamilyInfo[index].id,
              name: beneficiaryFamilyInfo[index].fName,
              boolvar: false,
            });
          });
        }
      });
  }

  fetchFamilyDocument() {
    if (parseInt(this.selectedId) === this.beneficiaryId) {
      this.loadDocumentList();
    } else {
      this.apiServices
        .getFamilyDocumentsList(this.beneficiaryId, this.selectedId)
        .subscribe((data: any) => {
          this.loadData = data["data"];
          this.buildDownloadCheckboxes();
        });
    }
  }
  loadDocumentList() {
    this.apiServices
      .getDocumentsList(this.beneficiaryId)
      .subscribe((data: any) => {
        this.loadData = data["data"];
        this.buildDownloadCheckboxes();
      });
  }

  openDoc(item) {
    const docPath = item.fileLocation.substring(23);
    window.open(`${environment.docs}${docPath}`, "_blank");
  }
  download(file) {
    this.uploadService.downloadDocuments(
      environment.docs + file.fileLocation.substring(23),
      file.fileLocation.substring(23)
    );
  }

  deleteDoc(item) {
    const title = "Confirm";
    const message = "Do you wish to Delete the File?";
    if (item) {
      this.dialogueInitializer(event, title, message)
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.removeElement.emit(item);
          }
        });
    }
  }

  dialogueInitializer(event, title: string, message: string) {
    const cordinates: MouseEventInit = event;
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: "400px",
      data: {
        update: { title, message },
        buttons: ["Cancel", "Delete"],
      },
    });
    return dialogRef;
  }

  buildDownloadCheckboxes() {
    this.bulkDownloadCheckboxes = [];
    this.selectedFiles = [];
    Object.keys(this.loadData).forEach((key) => {
      let tempNode = { isAllSelected: false, childItems: this.loadData[key] };
      tempNode.childItems.forEach((child, index) => {
        child["isSelected"] = false;
        child["itemIndex"] = index;
        this.loadData[key][index]["itemIndex"] = index;
      });
      this.bulkDownloadCheckboxes[key] = tempNode;
    });
  }

  selectAllDocs(event, node) {
    node.childItems.forEach((doc) => {
      doc["isSelected"] = node["isAllSelected"];
    });
    //update selected files
    this.buildSelectedFiles();
  }
  selectDoc(event, node) {
    const checkedItems = node?.childItems.filter(
      (doc) => doc.isSelected
    ).length;
    const totalItems = node?.childItems.length;
    node.isAllSelected = checkedItems == totalItems;
    //update selected files
    this.buildSelectedFiles();
  }

  buildSelectedFiles() {
    this.selectedFiles = [];
    Object.keys(this.bulkDownloadCheckboxes).forEach((key) => {
      this.bulkDownloadCheckboxes[key].childItems.forEach((doc) => {
        if (doc["isSelected"])
          this.selectedFiles.push({
            fileLocation: doc.fileLocation,
            fileName: doc.fileName,
          });
      });
    });
    // console.log('this.selectedFiles:',this.selectedFiles);
  }
}
