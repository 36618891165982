<div class="container-fluid padding-top-bottom-15 new-dashboard attorney-dashborad">
  <!-- <div class="row margin-bottom-15">
    <div class="col-12 text-center">
      <dashboard-large-banner-ad></dashboard-large-banner-ad>
    </div>
  </div> -->
  <div class="row margin-bottom-15">
    <!-- <div class="col-12">
      <div class="row m-0">
        <div class="col-6">
          <span class="user-name">Welcome {{ userName }}!</span>
        </div>
        <div class="col-6 align-self-center">
          <span class="user-time pull-right">
            <span class="material-icons pull-left">calendar_today</span><span>{{ userTimeNow }}</span>
          </span>
        </div>
      </div>
    </div> -->
    <div class="col-12">
      <div class="row m-0">
        <div class="col-8">
          <span class="user-name" *ngIf="roleName == 'Attorney'">Welcome {{ userName }}!</span>
        </div>
        <!-- <<<<<<<<<<<<<<<<<<<<<<<<< email-client >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  -->
        <div class="col-4 d-flex justify-content-end align-items-center">
          <!-- <div class="col-4 align-self-center pr-0 div_align">
          <div>
            <div style="border: 1px solid #19A0DA" class="pull-right rounded p-1 mail-align" >
          <span title="Go to My Mail" class="user-time  icn" routerLink="email-client">
            <mat-icon> mail_outline</mat-icon>
              <span class="pull-right calendar pl-1 pr-1">
                Mail</span>
          </span>
          </div>
        </div>
        </div> -->

          <!-- <<<<<<<<<<<<<<<<<<<<<<<<< calendar >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> -->

          <!-- <div class="align-self-center pr-0 ml-auto">
            <div>
              <div style="border: 1px solid #19A0DA" class="pull-left rounded p-1 mail-align">
                <span title="Go to My Mail" class="user-time  icn" routerLink="email-client">
                  <mat-icon> mail_outline</mat-icon>
                  <span class="pull-right calendar pl-1 pr-1">
                    Mail</span>
                </span>
              </div>

              <div style="border: 1px solid #19A0DA" class="pull-right rounded p-1 ml-2">
                <span title="Go to My Calendar" class="user-time  icn" routerLink="scheduling"
                  style="padding-left:10px !important;">
                  <span class="material-icons pull-left">calendar_today</span>
                  <span class="pull-right calendar">
                    Calendar</span>
                </span>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <div class="row margin-top-bottom-15">
    <div class="col-8 px-2">
      <task-list></task-list>
      <div class="row p-0 mt-3">
        <div class="col-6 p-0">
          <app-recent-activity></app-recent-activity>
          <!-- <app-recently-visited-task></app-recently-visited-task> -->
        </div>
        <div class="col-6 pr-0">
          <calendar-widget></calendar-widget>
        </div>
        <div class="col-12 px-0 py-3">
          <app-visa-bulletin></app-visa-bulletin>
        </div>
        <div class="col-6 p-0">
          <!--  <div class="col-6 p-0" *ngIf="roleName == 'Attorney'"> -->
           <information-graph></information-graph>
         </div>
         <!-- <div class="col-6" [ngClass]="{'p-0': roleName !== 'Attorney', 'pr-0': roleName == 'Attorney' }"> -->
         <div class="col-6 pr-0">
           <!-- <dashboard-team-members-list-preview></dashboard-team-members-list-preview> -->
           <app-task-deadline></app-task-deadline>
         </div>
      </div>
    </div>
    <div class="col-4 px-2">
      <div class="w-100 d-flex mb-3">
        <app-quick-access class="w-100"></app-quick-access>
      </div>
      <!-- <div class="w-100 d-flex mb-3 attorney-notifications"> -->
      <div class="w-100 d-flex mb-3">
        <div class="mt-2 w-100 bg-white">
          <div class="action-box">
            <div class="flex-row message-box px-3 btm-brder">
              <div>
                <ng-container *ngIf="this.alertsList.length > 0 ; else noalert">
                  <span class="badge p-2 btn-orange-alert">Alerts</span>
                </ng-container>
                <ng-template #noalert>
                    <!-- <span  class="badge p-2 badge-light">Alerts</span> -->
                 </ng-template>
                 
              </div>
              <div class="flex-col font-10 pl-4 mr-auto">
                  <ng-container *ngIf="this.alertsList.length > 0 ; else noAlertData" >
                    <span class=" text-left alert-text-header">
                      <strong>{{this.alertsList[alertWidgetPagination.currentIndex]['title']}}</strong>
                    </span>
                    <br>
                    <span class="text-left alert-text-para">
                      {{this.alertsList[alertWidgetPagination.currentIndex]['text']}}
                    </span>
                  </ng-container>
                  <!-- <ng-template #noAlertData>
                    <span>No alerts for you</span>
                  </ng-template> -->
                  <ng-template #noAlertData>
                    <span class="badge p-2 btn-orange-alert"><a [routerLink]="['./alerts']">Show Alerts</a></span>
                    <!-- <p class="show-more"><a [routerLink]="['./alerts']">Show More</a></p> -->
                  </ng-template>
              </div>
              <div class="pt-1 ml-4">
                <ul class="pagination">
      
                  <!-- <li [ngClass]="{'disabled-click':alertWidgetPagination.currentIndex == 0 }">
                    <a (click)="setContentIndex('dec')">
                      <span class="material-icons"> keyboard_arrow_left </span>
                    </a>
                  </li>
      
                  <li [ngClass]="{'disabled-click': alertWidgetPagination.currentIndex == alertWidgetPagination.maxIndex}">
                    <a (click)="setContentIndex('inc')">
                      <span class="material-icons">keyboard_arrow_right</span>
                    </a>
                  </li> -->
      
                </ul>
                <div class="index-track" *ngIf="this.alertsList.length > 0">
                  {{ this.alertWidgetPagination.currentIndex+1}}/{{ this.alertWidgetPagination.maxIndex+1}}
                </div>
              </div>
            </div>
            <!-- <div class="text-center">
              <p class="show-more"><a [routerLink]="['./alerts']">Show More</a></p>
            </div> -->
          </div>
        </div>
      </div>
      <div class="w-100 d-flex mb-3 attorney-notifications">
        <notification-list class="w-100"></notification-list>
      </div>
      <div class="w-100 d-flex mb-3 attorney-notifications">
        <app-individuals-date-tracker class="w-100"></app-individuals-date-tracker>
      </div>
      <div class="w-100 d-flex mb-3 attorney-notifications">
        <app-todo-checklist class="w-100"></app-todo-checklist>
      </div>
    </div>
  </div>
</div>