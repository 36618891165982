import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, pluck } from 'rxjs/operators';
import * as Highcharts from 'highcharts';
import { ImagilityBaseResponse } from '../data/models/response.model';
import { BehaviorSubject, Observable } from "rxjs";
@Injectable({
  providedIn: 'root'
})
export class DashboardWidgetsService {

  unReadCountBS: BehaviorSubject<number>;
  unReadCountObsvl: Observable<number>;
  editTodoItem = new BehaviorSubject<number>(0);
  modifyTodoItem = new BehaviorSubject<boolean>(false);
  editTodoItemObsvl$ = this.editTodoItem.asObservable();
  modifyTodoItemObsvl$ = this.modifyTodoItem.asObservable();

  constructor(private httpClient: HttpClient) {

    this.unReadCountBS = new BehaviorSubject<number>(0); //belll notification
    this.unReadCountObsvl = this.unReadCountBS.asObservable();
  }  
  getServiceCenterPremimum():Observable<any>{
    return this.httpClient.get(`/serviceCenter/true`).pipe(
      map(data => {
        return data;
      })
    );
   }
  getServiceCenterRegular():Observable<any>{
    return this.httpClient.get(`/serviceCenter/false`).pipe(
      map(data => {
        return data;
      })
    );
   }
   
  getTaskType() {
    return this.httpClient.get(`/task/superTask/NONIMMPET/taskTypes`).pipe(
      map(data => {
        return data;
      })
    );
  }

  getTaskList(payload) {
    return this.httpClient.post(`/task`, payload).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getStepViewSettingsList(payload) {
    return this.httpClient.post(`/task`, payload).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getTaskStepsList(payload) {
    return this.httpClient.post(`/policy/beneficiary/task/steps`, payload).pipe(
      map((data) => {
        return data;
      })
    );
  }

  // Local JSON
  getTaskStepsList_json(payload) {
    const benObj = new Observable(observer => {
      fetch('./assets/mocks/ben-step-view-setting.json', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => response.json())
        .then(data => {
          observer.next(data);
          observer.complete();
        }
        )
        .catch(error => console.error(error))
    })
    return benObj
  }

  getSuperTaskList(payload) {
    return this.httpClient.post(`/superTask/list`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  getSubTaskList(superTaskId) {
    return this.httpClient.post(`/superTask/${superTaskId}/subTask/list`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  getSubTasksType(superTaskType: string, petitionType?: string, visaType?: string) {
    let apiUrl = `/task/superTask/${superTaskType}/taskTypes`;
    if (petitionType && visaType) {
      apiUrl = `${apiUrl}?petitionType=${petitionType}&visaType=${visaType}`;
    }
    return this.httpClient
      .get(apiUrl)
      .pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return null;
          }
        })
      );
  }



  getSubTasksTypeListImmigrant(immgCategoryCode, immgClasificationCode) {
    return this.httpClient.get(`/subtask/list/immgCategory/${immgCategoryCode}/immgClasification/${immgClasificationCode}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }

  getSubTaskTypeListForFamilyImmigration(superTaskTypeCode: string, immgCategoryCode: string, immgClassificationCode: string) {
    return this.httpClient
      .get(`/task/superTask/${superTaskTypeCode}/taskTypes?immCat=${immgCategoryCode}&immClas=${immgClassificationCode}`)
      .pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return null;
          }
        })
      );
  }

  getTaskListDetails(superTaskId) {
    return this.httpClient.get(`/getSuperTaskById/${superTaskId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200 || response.status === 204) {
          return response;
        }
      })
    );
  }


  getTaskListForAttorney(attorneyId, payload) {
    return this.httpClient.post(`/superTask/attorney/${attorneyId}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
        else {
          return null;
        } //IM 4149
      })
    );
  }

  getTaskListForBeneficiary(beneficiaryId, payload) {
    return this.httpClient.post(`/superTask/beneficiary/${beneficiaryId}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }

  deleteTaskId(taskId) {
    return this.httpClient.delete(`/task/${taskId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return true;
        }
      })
    );
  }

  getPlatformStatistics() {
    return this.httpClient.get('/stats').pipe(
      map(data => {
        return data;
      })
    );
  }

  getNotificationList(companyId, companyType, []) {
    return this.httpClient.post('/company/' + companyId + '/companyType/' + companyType + '/linkrequest', []).pipe(
      map(data => {
        return data;
      })
    );
  }


  setBellNotifyCount(id: number) {
    this.unReadCountBS.next(id);
  }

  getBellNotifyCount() {
    return this.unReadCountObsvl;
  }


  getNotifications(payload) {
    return this.httpClient.post(`/notification`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
        else {
          return null;
        }
      }));
  }

  updateNotificationStatus(notificationId, payload) {
    return this.httpClient.post(`/notification/${notificationId}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
        else {
          return null;
        }
      }));
  }

  setBellIconCountOnHeader() {
    let payload = {
      "isActionable": null,
      "isNotifyGroup": null,
      "isRead": null,
      "isStarred": null,
      "pageNumber": 0,
      "recordsPerPage": 0
    };
    this.getNotifications(payload).subscribe(response => {
      if (response) {
        this.setBellNotifyCount(response['unReadCount']);
      }
    });

  }
  getNotificationListUpdated(payload) {
    return this.httpClient.post('/notification', payload).pipe(
      map(data => {
        return data;
      })
    );
  }

  getQuestionnaireNotificationListUpdated(payload, data) {
    return this.httpClient.post(`/questionnary/beneficiaryNotification/${payload.beneficiaryId}/${payload.companyId}`, data).pipe(
      map(data => {
        return data;
      })
    );
  }


  postUpdateNotificationRequest(payload) {
    return this.httpClient.post(`/linkrequest/${payload.linkRequestId}/status/${payload.statusCode}/update`, payload).pipe(
      map(data => {
        return data;
      })
    );
  }

  // alerts API//
  getAlertsList() {
    return this.httpClient.get(`/alerts`).pipe(map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response;
      }
      else {
        return null;
      }
    }));
  }

  getAlertsListNew() {
    return this.httpClient.get(`/empAlerts`).pipe(map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response;
      }
      else {
        return null;
      }
    }));
  }

  updateAlertStatus(action, numDays, payload) {
    let api = '';
    if (action == 'snooze') {
      api = `/alerts/snooze/${numDays}/DAY`;
    }
    else {
      api = '/alerts/dnd';
    }
    return this.httpClient.post(api, payload).pipe(map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response;
      }
      else {
        return null;
      }
    }));
  }


  getPetitionInfo(payload) {
    return this.httpClient.post('/petitionInfo?fromDate=' + payload.fromDate + '&toDate=' + payload.toDate + '&serviceCenterId='+ payload.serviceCenterId, []).pipe(
      map(data => {
        return data;
      })
    );
  }

  getServiceCenterName(companyId) {
    return this.httpClient.get('/servicecenters/company/' + companyId).pipe(
      map(data => {
        return data;
      })
    );
  }

  createChart(el, cfg) {
    Highcharts.chart(el, cfg);
  }

  getStatusAndResponse(stepTypeCode: string) {
    return this.httpClient.get(`/step/${stepTypeCode}/statusAndResponse`).pipe(
      map(response => {
        return response['data'];
      })
    );
  }

  submitTaskStatusResponse(taskId, stepId, payload) {
    return this.httpClient.post(`/task/${taskId}/step/${stepId}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status == 200) {
          return true;
        } else {
          return false;
        }
      })
    );
  }

  uploadReceipt(formData, receiptType, receiptCategory, receiptNo, taskStepId) {
    return this.httpClient.post(`/receipt/type/${receiptType}/category/${receiptCategory}/receiptNo/${receiptNo}/taskStep/${taskStepId}`, formData).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }
  getReceipts(receiptType, taskStepId) {
    return this.httpClient.get(`/taskStepId/${taskStepId}/receipt/${receiptType}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }
  getPetitionDecision(petitionId) {
    return this.httpClient.get(`/petition/${petitionId}/petitionDecision`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }
  savePetitionDecision(petitionId, payload) {
    return this.httpClient.post(`/petition/${petitionId}/petitionDecision`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return true;
        } else {
          return false;
        }
      })
    );
  }
  getContactDetailsPetitioner(beneficiaryId) {
    return this.httpClient.get(`/beneficiary/${beneficiaryId}/petitioner`).pipe(
      map(data => {
        return data;
      })
    );
  }
  getContactDetailsAttorney(beneficiaryId) {
    return this.httpClient.get(`/beneficiary/${beneficiaryId}/attorney`).pipe(
      map(data => {
        return data;
      })
    );
  }

  getTeamMemeberslist(companyId) {
    return this.httpClient.get(`/petitioner/admin/dashboard/users/company/${companyId}`).pipe(
      map(data => {
        return data;
      })
    );
  } //IM -3882 3555

  updatePetitionDecision(petitionId, payload) {
    return this.httpClient.post(`/petition/${petitionId}/petitionDecision`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  lcaAssignedNotify(companyId, petitionId, payload) {
    return this.httpClient.post(`/lca/notification/company/${companyId}/petition/${petitionId}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  acceptNotice(notificationId) {
    return this.httpClient.get(`/companyNotice/petitioner/eta/notification/${notificationId}/accept`).pipe(
      map(data => {
        return data;
      })
    );
  }

  ignoreNotice(notificationId) {
    return this.httpClient.get(`/companyNotice/petitioner/eta/notification/${notificationId}/ignore`).pipe(
      map(data => {
        return data;
      })
    );
  }

  getSuperTaskId(taskId) {
    return this.httpClient.get(`/task/${taskId}/superTask`).pipe(
      map(data => {
        return data;
      })
    );
  }

  updateRequest(linkRequestId, statusCode, notificationId) {
    return this.httpClient.post(`/linkrequest/${linkRequestId}/status/${statusCode}/${notificationId}`, {}).pipe(
      map(data => {
        return data;
      })
    );
  }

  caseAutomationSettings(payload,isEdit){
    let url;
    if(isEdit){
      url=`/policies/petitionNotificationSettings?isUpdate=true`
    }
    else {
      url=`/policies/petitionNotificationSettings`
    }

    return this.httpClient.post(url, payload).pipe(
      map(data => {
        return data;
      })
    );
  }

  // /api/v1/getAllVisaCaseRequestList
  getCaseRequest(companyId,suptsktyp,payload){
    return this.httpClient.post(`/getAllVisaCaseRequestList?companyId=${companyId}&suptsktyp=${suptsktyp}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  // getCaseRequest(companyId,suptsktyp,payload){
  //   return this.httpClient.post(`/getAllVisaCaseRequestList?companyId=${companyId}&suptsktyp=${suptsktyp}&searchText=${payload.searchText}`, payload).pipe(
  //     map((response: ImagilityBaseResponse) => {
  //       return response;
  //     })
  //   );
  // }

  deleteSuperTask(superTaskId: number) {
    return this.httpClient.delete(`/superTask/${superTaskId}`).pipe(
      map((response: ImagilityBaseResponse) => {
          return response;
      })
    );
  }

  getIndividualTracker() {
    let customId;
    if(sessionStorage.getItem('userTypeRole')=='Petitioner'){
      customId = `petitionerId=${sessionStorage.getItem('companyId')}`
    } else if(sessionStorage.getItem('userTypeRole')=='Attorney'){
      customId = `attorneyId=${sessionStorage.getItem('companyId')}`
    } else if(sessionStorage.getItem('userTypeRole')=='Beneficiary'){
      customId = `beneficiaryId=${sessionStorage.getItem('beneficiaryId')}`
    }
    return this.httpClient.get(`/visaBulletin/individualTracker?${customId}`).pipe(
      map(data => {
        return data;
      })
    );
  }

  getPendingStepTasks(payload: any) {    
    return this.httpClient.post(`/getTasksForUser`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

   ignorePendingOverdueTask(taskId: number) {    
    return this.httpClient.post(`/markIgnore/${taskId}`, '').pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  getToDoCategoryList():Observable<any>{
    return this.httpClient.get(`/getCategoryByLoggedInUser`).pipe(
      map(data => {
        return data;
      })
    );
   }

   saveToDoCategory(payload: any) {        
    return this.httpClient.post(`/saveCategoryDetails`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  deleteCategoryById(categoryId: any) {
    return this.httpClient.delete(`/deleteCategory/${categoryId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  saveToDoChecklist(payload: any) {        
    return this.httpClient.post(`/saveTODODetails`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  getToDoList():Observable<any>{
    return this.httpClient.get(`/getToDoList`).pipe(
      map(data => {
        return data;
      })
    );
   }

   getToDoListByStatus(status:any):Observable<any>{
    return this.httpClient.get(`/getToDoList?status=${status}`).pipe(
      map(data => {
        return data;
      })
    );
   }

   getToDoListByCategory(categoryId:any):Observable<any>{
    return this.httpClient.get(`/getToDoList?categoryId=${categoryId}`).pipe(
      map(data => {
        return data;
      })
    );
   }

   deleteTodoItemById(ToDoId: any) {
    return this.httpClient.delete(`/deleteToDoDetails/${ToDoId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  markCompleteToDoChecklist(ToDoId: any) {            
    return this.httpClient.post(`/markComplete/toDoDetail/${ToDoId}`, '').pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {          
          return response;
        }
      })
    );
  }

  setEditedTodoId(data: any) {
    this.editTodoItem.next(data);
  }

  setModifyTodoItem(data: boolean){
    this.modifyTodoItem.next(data)
  }
  

}
