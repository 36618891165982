import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { PetitionerService } from 'src/app/services/petitioner.service';
import moment from 'moment';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DashboardWidgetsService } from 'src/app/services/dashboard-widgets.service';
import { UserPrivacyModalComponent } from 'src/app/modules/shared/modal/user-privacy-modal/user-privacy-modal.component';
import { UserRoleService } from 'src/app/services/user-role.service';
import { TourModalComponent } from 'src/app/modules/shared/modal/tour-modal/tour-modal.component';

@Component({
  selector: 'app-dashboard-attorney',
  templateUrl: './dashboard-attorney.component.html',
  styleUrls: ['./dashboard-attorney.component.scss']
})
export class DashboardAttorneyComponent implements OnInit {

  public userName: string;
  public userTimeNow: string;
  public teamMemberCount: number;
  public companyId = '';
  public userId = '';
  dialogContent;
  alertsList = [];
  policyId;
  public employees: any;
  color = [];
  showMiniAds = false;
  roleName: any;
  public alertWidgetPagination = { currentIndex: 0, maxIndex: 0 };
  constructor(private router: Router,
    public dialog: MatDialog,
    private userRoleService: UserRoleService,
    private authenticationService: AuthenticationService,
    private dashboardWidgetsService: DashboardWidgetsService,
    private petitionerService: PetitionerService,
    private route: ActivatedRoute) {
    this.userName = "";
    this.userTimeNow = moment(new Date()).format("dddd, MMMM Do YYYY");
    this.alertWidgetPagination['currentIndex'] = 0;
    this.alertWidgetPagination['maxIndex'] = 0;

  }

  ngOnInit() {
    this.companyId = sessionStorage.getItem('companyId');
    this.userId = sessionStorage.getItem('userId');
    this.getuserName();
    this.getNotifications();
    //this.getAllAlerts()
    let userType;
    if (localStorage.getItem('policyAcceptanceRequired') == "true") {
      switch (sessionStorage.getItem('userTypeRole')) {
        case 'Petitioner': userType = 'PETNR'; break;
        case 'Beneficiary': userType = 'BEN'; break;
        case 'Attorney': userType = 'ATRNY'; break;
        default: userType = 'BEN';
      }


      this.userRoleService.getPolicyContent(userType, 'PRVCYPLCY').subscribe((data) => {
        if (data) {
          this.dialogContent = data.content;
          this.policyId = data.policyId;
          this.displayPrivacyPolicy();
        }
      });
    }
  }

  getAllAlerts() {
    this.dashboardWidgetsService.getAlertsListNew().subscribe((response: Object) => {
      if (response) {
        this.alertsList = response['data'];
        if (this.alertsList.length > 0) {
          this.alertWidgetPagination.maxIndex = this.alertsList.length > 6 ? 5 : this.alertsList.length - 1;
        }
      }
      else {
        this.alertsList = [];
      }
    });
  }

  setContentIndex(action) {
    if (action == "inc") {
      this.alertWidgetPagination.currentIndex == this.alertWidgetPagination.maxIndex ?
        '' : this.alertWidgetPagination.currentIndex = this.alertWidgetPagination.currentIndex + 1;
    }

    if (action == "dec") {
      this.alertWidgetPagination.currentIndex == 0 ?
        '' : this.alertWidgetPagination.currentIndex = this.alertWidgetPagination.currentIndex - 1;
    }


  }

  displayIntroDialog() {
    this.dialog.open(AttorneyWelcomeDialog,
      {
        height: '740px',
        width: '800px',
      }).afterClosed().subscribe(result => {
        localStorage.setItem('isFirstLogin', "false");
      });
  }

  displayPrivacyPolicy() {
    let type = 'privacy';
    const dialogRef = this.dialog.open(UserPrivacyModalComponent, {
      width: '800px',
      height: 'auto',
      data: {
        update: {
          policyId: this.policyId,
          dialogContent: this.dialogContent,
          type: type
        }
      }
    }).afterClosed().subscribe(result => {
      if (result == 'acceptPolicy') {
        if (localStorage.getItem('isFirstLogin') == "true")
          this.displayIntroDialog();
      }
    });
  }


  addNewTask() {
    //this.router.navigate(['petitioner-landing/dashboard/task',  { id: 5 }]);
    sessionStorage.removeItem('taskId');
    this.router.navigate(['petitioner-landing/dashboard/task/task-details', 0]);
  }
  getuserName() {
    this.authenticationService.currentUser.subscribe((data) => {
      if (data) {
        this.roleName = data.roleName;
        this.userName = data.firstname;
        // if(data['permissions']){
        //   data['permissions'].find(el => {
        //     if(el === 'ISADMIN') {
        //       this.showMiniAds = true;
        //     }
        //   });
        // }
        //this.userName=  data.firstname+data.lastName;
      }
    });
  }

  getNotifications() {
    let payload = {
      isActionable: null,
      isNotifyGroup: null,
      isRead: null,
      isStarred: null,
      pageNumber: 0,
      recordsPerPage: 0
    }
    this.dashboardWidgetsService.getNotifications(payload).subscribe((response) => {
      this.dashboardWidgetsService.setBellNotifyCount(response ? response['unReadCount'] : 0);
    });
  }
}

@Component({
  selector: 'attorney-welcome-dialog',
  templateUrl: 'attorney-welcome-dialog.html',
})
export class AttorneyWelcomeDialog {

  constructor(public dialogRef: MatDialogRef<AttorneyWelcomeDialog>,
    public dialog: MatDialog) {

  }

  displayStartTourDialog() {
    this.dialogRef.close();
    this.dialog.open(TourModalComponent,
      {
        width: '650px',
        data: {
          update: {
            userType: sessionStorage.getItem('userTypeRole')
          }
        }
      }).afterClosed().subscribe(result => {

      });
  }
}
